import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useAppContext } from "../context/store";
import DateNavigation from "../component/date_navigate";
import { CardText } from "react-bootstrap-icons";
import CategoryModal from "./modal/category";
import AppConfigurationForm from "./form/app_configuration";
import { useLanguage } from "../context/language";

export default function Configure() {
  const [get, set] = useAppContext();
  const [T] = useLanguage();
  const [categories, setCategories] = useState([]);
  const [addCategory, setAddCategory] = useState(false);

  useEffect(() => {
    if (!addCategory) {
      set.categories().then((response) => {
        response ? setCategories(response) : alert("Something went wrong!");
      });
    }
  }, [addCategory]);

  return (
    <Container className="dashboard-header">
      <Row>
        <Col>
          <AppConfigurationForm
            data={get}
            onSave={(form) => set.state(form, "I KNOW WHAT I AM DOING")}
          />
        </Col>
        <Col>
          <div className="card border-light mb-3 card-view">
            <div className="card-header">
              <DateNavigation
                onAdd={() => setAddCategory({})}
                text="Categories"
              />
            </div>
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">{T("Name")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, i) => (
                    <tr key={category.id + i}>
                      <th scope="row">{category.name}</th>
                      <th
                        onClick={() => setAddCategory(category)}
                        className="td-action"
                      >
                        <CardText />
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>

      {addCategory ? (
        <CategoryModal data={addCategory} onHide={() => setAddCategory(null)} />
      ) : null}
    </Container>
  );
}
