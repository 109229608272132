import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { useAppContext } from "../context/store";
import { useLanguage } from "../context/language";

const routesStandard = [
  { title: "Dashboard", link: "/" },
  { title: "Clients", link: "/clients" },
  { title: "Reports", link: "/reports" },
  { title: "Configure", link: "/configure" },
];

const routesAdministrator = [
  { title: "Dashboard", link: "/" },
  { title: "Clients", link: "/clients" },
  { title: "Reports", link: "/reports" },
  { title: "Users", link: "/users" },
];

export default function NavigationBar() {
  const [get, set] = useAppContext();
  const [route, setRoute] = useState("/");
  const [showSearch, setShowSearch] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  const [clientsResult, setClientsResult] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [T, language] = useLanguage();

  useEffect(() => setShowSearch(route === "/clients" || route === "/"), [
    route,
  ]);
  useEffect(() =>
    setRoutes(get.user.type_id === 1 ? routesAdministrator : routesStandard)
  );
  useEffect(() => {
    setTimeout(() => {
      language.change("al");
    }, 1000);
  }, []);

  useEffect(() => {
    if (searchClient !== "") {
      let substr = searchClient.toLowerCase();
      setClientsResult(
        get.clients.filter(
          (client) =>
            client.name.toLowerCase().includes(substr) ||
            client.surname.toLowerCase().includes(substr) ||
            client.contact.toLowerCase().includes(substr) ||
            client.email.toLowerCase().includes(substr)
        )
      );
    }
  }, [searchClient]);

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Navbar.Brand>
        <Link to="/" onClick={() => setRoute("/")} className="link">
          <div style={{ position: "absolute", left: "15px", top: "5px" }}>
            <img
              src="/logo-white.png"
              height="58px"
              style={{ marginRight: "10px" }}
            />
          </div>
          <div style={{ width: "65px", display: "inline-block" }}></div>
          {T("BEE APPOINTMENT")}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {routes.map((r, i) => (
            <Nav className="nav-item" key={r.link + i}>
              <Link
                to={r.link}
                onClick={() => setRoute(r.link)}
                className="nav-link"
              >
                {T(r.title)}
              </Link>
            </Nav>
          ))}
        </Nav>
        {showSearch ? (
          <form
            className="form-inline my-2 my-lg-0"
            style={{ marginRight: "10px" }}
          >
            <input
              className="form-control mr-sm-2"
              type="text"
              placeholder={
                get.selectedClientID === -1
                  ? T("Search Client")
                  : get.clients.filter((c) => c.id === get.selectedClientID)[0]
                      .name
              }
              value={searchClient}
              onChange={(e) => setSearchClient(e.target.value)}
            />
            {get.selectedClientID !== -1 ? (
              <button
                className="btn btn-secondary my-2 my-sm-0"
                type="button"
                onClick={() => set.selectedClientID(-1)}
              >
                {T("Cancel")}
              </button>
            ) : null}
          </form>
        ) : null}
        <Nav>
          <NavDropdown
            title={`${get.user.name} ${get.user.surname}`}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item href="#">{T("Profile")}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={set.logout}>
              {T("Log Out")}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <ClientsList
        list={clientsResult}
        onItemClick={(client) => {
          setSearchClient("");
          setClientsResult([]);
          set.selectedClientID(client.id);
        }}
        show={clientsResult.length > 0}
      />
    </Navbar>
  );
}

const ClientsList = ({ list, show = true, onItemClick }) => {
  if (list.length <= 0 || !show) {
    return null;
  }

  return (
    <div
      className="list-position-absolute"
      style={{ bottom: "unset", top: "100%" }}
    >
      <Row className="list-item-header">
        <Col>{"Name"}</Col>
        <Col>{"Surname"}</Col>
        <Col>{"Email"}</Col>
        <Col>{"Contact"}</Col>
      </Row>
      {list.map((item, i) => (
        <Row
          key={item.name + i}
          className="list-item"
          onMouseDown={() => onItemClick(item)}
        >
          <Col>
            <b>{item.name}</b>
          </Col>
          <Col>
            <b>{item.surname}</b>
          </Col>
          <Col>
            <b>{item.email}</b>
          </Col>
          <Col>
            <b>{item.contact}</b>
          </Col>
        </Row>
      ))}
    </div>
  );
};
