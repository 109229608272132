import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/store";
import DateNavigation from "../../component/date_navigate";
import DayCard from "../../component/daycard";
import AppointmentModal from "../modal/appointment";
import { useLanguage } from "../../context/language";

export default function ListView({ query }) {
  const [get, set] = useAppContext();
  const [T] = useLanguage();
  const [appointments, setAppointments] = useState([]);
  const [fillSlot, setFillSlot] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    set.listAppointments({}).then((response) => {
      console.log(response);
      if (typeof response === "object") {
        setAppointments(response);
      } else {
        setLoading(false);
      }
    });
  }, [query, get.selectedClientID, get.selectedUserID]);

  useEffect(() => {
    setLoading(false);
  }, [appointments]);

  const getFullName = () => {
    if (get.selectedClientID === -1) {
      return " ";
    }
    let client = get.clients.filter((c) => c.id === get.selectedClientID)[0];
    return client.name + " " + client.surname;
  };

  return (
    <>
      <div className="card-header">
        <DateNavigation
          loading={loading}
          text={getFullName()}
          selectUser={true}
        />
      </div>
      <div className="card-body">
        {appointments.map((appointment, i) => (
          <DayCard
            key={i}
            data={appointment}
            onClick={() => setFillSlot(appointment)}
            compact
            date
          />
        ))}

        {fillSlot ? (
          <AppointmentModal data={fillSlot} onHide={() => setFillSlot(null)} />
        ) : null}
      </div>
    </>
  );
}
