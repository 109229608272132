import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/store";
import DateNavigation from "../../component/date_navigate";
import { format } from "date-fns";
import { Row, Col } from "react-bootstrap";
import { useLanguage } from "../../context/language";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function WeekView({ query }) {
  const [get, set] = useAppContext();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState({});
  const [T] = useLanguage();

  useEffect(() => {
    setLoading(true);
    if (!query) {
      query = {
        start_time: new Date(get.date.year, get.date.month, get.date.day),
        end_time: new Date(get.date.year, get.date.month, get.date.day + 7),
      };
    }

    set.appointments(query).then((response) => setAppointments(response));
  }, [query, get.date, get.selectedUserID, get.selectedClientID]);

  useEffect(() => {
    setLoading(false);
  }, [appointments]);

  return (
    <>
      <div className="card-header">
        <DateNavigation
          loading={loading}
          onPrev={() => set.date({ day: get.date.day - 7 })}
          timePicker={true}
          onNext={() => set.date({ day: get.date.day + 7 })}
          onReset={set.resetDate}
          selectUser={true}
        />
      </div>
      <div className="card-body">
        {/* <Row>
          {weekDays.map((day, i) => (
            <Col key={i + day} className="text-center">
              {T(day)}
            </Col>
          ))}
        </Row> */}
        <Row>
          {weekDays.map((day, i) => {
            let date = new Date(
              get.date.year,
              get.date.month,
              get.date.day + i
            ).getDate();
            let slots = appointments[date];

            if (typeof slots === "object" && slots.length > 0) {
              return (
                <>
                  <Col
                    xs={12}
                    key={i + day}
                    className="text-center col-sm"
                    style={{ padding: "0" }}
                  >
                    {T(day)}

                    <Col key={day + i} style={{ padding: "0.2rem" }}>
                      {slots.map((slot, i) => {
                        let usr = get.users.filter(
                          (u) => u.id === slot.user_id
                        );
                        let userStyle = {
                          borderLeftColor:
                            usr.length > 0 ? usr[0].color : "#FFFFFF",
                        };

                        return (
                          <div
                            key={slot.id + i}
                            className="align-items-center day-card week-appointment"
                            style={userStyle}
                          >
                            <div
                              style={{
                                margin: "0.2rem 0.5rem",
                                color: "rgb(150,150,150)",
                              }}
                            >
                              {format(new Date(slot.start_time), "HH:mm")}
                              <br></br>
                              {format(new Date(slot.end_time), "HH:mm")}
                            </div>
                            <div style={{ margin: "0.2rem 0.5rem" }}>
                              {usr.length > 0 ? usr[0].name : ""}
                              <br></br>
                              {usr.length > 0 ? usr[0].surname : ""}
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Col>
                </>
              );
            }
            return (
              <Col xs={12} key={i + day} className="text-center col-sm">
                {T(day)}
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}
