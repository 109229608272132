import React, { useState, useEffect } from "react";
import TimePicker from "../../component/form/time_picker";
import DateNavigation from "../../component/date_navigate";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLanguage } from "../../context/language";

const initialForm = (data) => ({
  dayLowerLimit: new Date().setHours(8, 0, 0),
  dayUpperLimit: new Date().setHours(18, 0, 0),
  dayPauseStart: new Date().setHours(12, 0, 0),
  dayPauseEnd: new Date().setHours(13, 0, 0),
});

export default function AppConfigurationForm({ data, onSave }) {
  const [T] = useLanguage();
  const [form, setForm] = useState(initialForm);
  const updateForm = (fields) => setForm({ ...form, ...fields });

  useEffect(() => {
    updateForm({
      dayLowerLimit: data.dayLowerLimit,
      dayUpperLimit: data.dayUpperLimit,
      dayPauseStart: data.dayPauseStart,
      dayPauseEnd: data.dayPauseEnd,
    });
  }, [data]);

  return (
    <div className="card border-light mb-3 card-view">
      <div className="card-header">
        <DateNavigation text="App Configuration" />
      </div>
      <div className="card-body">
        <Row className="align-items-center">
          <Col sm={4} className="d-flex justify-content-center">
            <div>
              <b>Daily Schedule</b>
            </div>
          </Col>
          <Col sm={8}>
            <Row>
              <TimePicker
                label="From Time"
                selected={form.dayLowerLimit}
                onChange={(time) => updateForm({ dayLowerLimit: time })}
              />
              <TimePicker
                label="To Time"
                selected={form.dayUpperLimit}
                onChange={(time) => updateForm({ dayUpperLimit: time })}
              />
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={4} className="d-flex justify-content-center">
            <div>
              <b>{T("Daily Pause Schedule")}</b>
            </div>
          </Col>
          <Col sm={8}>
            <Row>
              <TimePicker
                label={T("From Time")}
                selected={form.dayPauseStart}
                onChange={(time) => updateForm({ dayPauseStart: time })}
              />
              <TimePicker
                label={T("To Time")}
                selected={form.dayPauseEnd}
                onChange={(time) => updateForm({ dayPauseEnd: time })}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="d-flex justify-content-center">
            <div>
              <b>{T("Work Days")}</b>
            </div>
          </Col>
          <Col sm={8}>
            <Row>
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <Col key={day + "key"}>
                  <Form.Group controlId={day + "check"}>
                    <Form.Check custom label={T(day)} type="checkbox" />
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={4} className="d-flex justify-content-center">
            <Button onClick={() => onSave(form)}>{T("Save Changes")}</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
