import { useState, useContext, createContext, useEffect } from "react";
import API from "../api/api";

const userKey = "APP-User";
const loginKey = "APP-Login";

export const Context = createContext();

export function useAppContext() {
  return useContext(Context);
}

const setTodayHours = (date, dateTime) =>
  new Date(
    date.year,
    date.month,
    date.day,
    new Date(dateTime).getHours(),
    new Date(dateTime).getMinutes(),
    0
  );

const defaultConfig = (date) => ({
  day_start_time: new Date().setHours(8, 0, 0),
  day_end_time: new Date().setHours(18, 0, 0),
  pause_start_time: new Date().setHours(12, 0, 0),
  pause_end_time: new Date().setHours(13, 0, 0),
  week_work_days: [1, 2, 3, 4, 5],
});

const initialState = {
  user: {
    name: "test",
    state: 1,
    type_id: 3,
  },
  selectedUserID: -1,
  selectedClientID: -1,
  types: { 1: "Administrator", 2: "Manager", 3: "Standard" },
  authenticated: false,
  appointments: { data: [], slots: [] },
  clients: [],
  users: [],
  date: {
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  },
  categories: [],
};

const api = new API();

export default function InitAppStore() {
  const [state, setState] = useState(initialState);

  const set = {
    state: (fields, key) => {
      if (key === "I KNOW WHAT I AM DOING") {
        setState((state) => ({
          ...state,
          ...fields,
        }));
      }
    },
    user: (data) => {
      setState((state) => ({
        ...state,
        user: data,
      }));
    },
    selectedUserID: (userID) => {
      setState((state) => ({
        ...state,
        selectedUserID: userID,
      }));
    },
    selectedClientID: (clientID) => {
      setState((state) => ({
        ...state,
        selectedClientID: clientID,
      }));
    },
    login: (data, rememberUser) => {
      api.login(data).then((response) => {
        if (response.status === 200) {
          if (typeof response.data !== "object") {
            alert("Wrong username or password");
            return;
          }
          if (response.data.session === "") {
            alert("Wrong username or password");
            return;
          }
          localStorage.setItem(userKey, JSON.stringify(response.data));
          if (rememberUser) {
            localStorage.setItem(loginKey, data.email);
          } else {
            localStorage.removeItem(loginKey);
          }
          setState((state) => ({
            ...state,
            authenticated: true,
            user: response.data,
          }));
          updateState();
          return true;
        }
        alert("Wrong username or password");
        return false;
      });
    },
    logout: () => {
      api.logout().then((ok) => {
        if (ok) {
          localStorage.removeItem(userKey);
          window.location.href = "/";
        }
      });
    },
    date: (data) => {
      console.log("Changing date");
      setState((state) => ({
        ...state,
        date: {
          ...state.date,
          ...data,
        },
      }));
    },
    resetDate: () => {
      setState((state) => ({
        ...state,
        date: {
          day: new Date().getDate(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
        },
      }));
    },
    authenticated: (isAuthenticated) => {
      return setState((state) => ({
        ...state,
        authenticated: isAuthenticated,
      }));
    },
    dayAppointments: (query) => {
      if (state.selectedUserID !== -1) {
        query = {
          ...query,
          user_id: state.selectedUserID,
        };
      }
      if (state.selectedClientID !== -1) {
        query = {
          ...query,
          client_id: state.selectedClientID,
        };
      }
      api.readAppointments(query).then((response) => {
        console.log(response);
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            appointments: {
              data: response.data,
              slots: daySlots(state, response),
            },
          }));
        }
      });
    },
    listAppointments: async (query) => {
      if (state.selectedUserID !== -1) {
        query = {
          ...query,
          user_id: state.selectedUserID,
        };
      }
      if (state.selectedClientID === -1) {
        return;
      }
      query = {
        ...query,
        client_id: state.selectedClientID,
      };

      return api.readAppointmentsByClient(query).then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      });
    },
    appointments: async (query) => {
      if (state.selectedUserID !== -1) {
        query = {
          ...query,
          user_id: state.selectedUserID,
        };
      }
      if (state.selectedClientID !== -1) {
        query = {
          ...query,
          client_id: state.selectedClientID,
        };
      }
      return api.readAppointments(query).then((response) => {
        if (response.status === 200) {
          return monthSlots(response);
        }
        return {};
      });
    },
    newAppointment: async (data) => {
      return api.newAppointment(data).then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            date: {
              ...state.date,
            },
          }));
          return true;
        }
        return false;
      });
    },
    editAppointment: async (data) => {
      return api.editAppointment(data).then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            date: {
              ...state.date,
            },
          }));
          return true;
        }
        return false;
      });
    },
    deleteAppointment: async (data) => {
      return api.deleteAppointment(data).then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            date: {
              ...state.date,
            },
          }));
          return true;
        }
        return false;
      });
    },
    clients: async (query) => {
      return api.readClients(query).then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            clients: response.data,
          }));
          return response.data;
        }
        return null;
      });
    },
    newClient: async (data) => {
      return api
        .newClient(data)
        .then((response) => (response.status === 200 ? response.data : null));
    },
    editClient: async (data) => {
      return api
        .editClient(data)
        .then((response) => (response.status === 200 ? true : false));
    },
    users: async (query) => {
      return api.readUsers(query).then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            users: response.data,
          }));
          return response.data;
        }
        return null;
      });
    },
    newUser: async (data) => {
      return api
        .newUser(data)
        .then((response) => (response.status === 200 ? response.data : null));
    },
    editUser: async (data) => {
      return api
        .editUser(data)
        .then((response) => (response.status === 200 ? true : false));
    },
    categories: async () => {
      return api.readCategories().then((response) => {
        if (response.status === 200) {
          setState((state) => ({
            ...state,
            categories: response.data,
          }));
          return response.data;
        }
        return null;
      });
    },
    newCategory: async (data) => {
      return api
        .newCategory(data)
        .then((response) => (response.status === 200 ? true : false));
    },
    editCategory: async (data) => {
      return api
        .editCategory(data)
        .then((response) => (response.status === 200 ? true : false));
    },
  };

  useEffect(() => updateState(), []);
  const updateState = () => {
    set.clients().then((response) => {
      if (response !== null) {
        set.authenticated(true);
        let userData = JSON.parse(localStorage.getItem(userKey));
        if (userData !== null) {
          set.user(userData);
        }
      }
    });
    set.users();
    set.categories();
  };

  return [state, set];
}

function monthSlots(response) {
  let slots = {};

  response.data.forEach((appointment) => {
    let day = new Date(appointment.start_time).getDate().toString();

    if (typeof slots[day] === "undefined") {
      slots[day] = [];
    }
    slots[day].push(appointment);
  });

  return slots;
}

function daySlots(state, response) {
  let slots = [];
  let user = state.user;
  if (state.selectedUserID !== -1) {
    user = state.users.filter((u) => u.id === state.selectedUserID)[0];
  }

  let dayPauseStart = user.config
    ? setTodayHours(state.date, user.config.pause_start_time)
    : setTodayHours(state.date, defaultConfig.pause_start_time);
  let dayPauseEnd = user.config
    ? setTodayHours(state.date, user.config.pause_end_time)
    : setTodayHours(state.date, defaultConfig.pause_end_time);

  if (
    dayPauseStart < dayPauseEnd &&
    response.data.filter((s) => s.pause).length === 0
  ) {
    let slot = {
      pause: true,
      start_time: dayPauseStart,
      end_time: dayPauseEnd,
    };
    response.data.push(slot);
    let sortedData = response.data.sort(
      (a, b) =>
        new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
    );
    response.data = sortedData;
  }

  let dayStart = user.config
    ? setTodayHours(state.date, user.config.day_start_time)
    : setTodayHours(state.date, defaultConfig.day_start_time);
  let dayEnd = user.config
    ? setTodayHours(state.date, user.config.day_end_time)
    : setTodayHours(state.date, defaultConfig.day_end_time);

  response.data.forEach((appointment, i) => {
    let slot =
      i === 0
        ? {
            start_time: dayStart,
            end_time: dayStart,
          }
        : {
            start_time: new Date(response.data[i - 1].end_time),
            end_time: new Date(response.data[i - 1].end_time),
          };

    let startTime = new Date(appointment.start_time);
    let endTime = new Date(appointment.end_time);
    let endTimeWithTolerance = new Date(
      new Date(slot.end_time).setMinutes(slot.end_time.getMinutes() + 1)
    );

    if (startTime > endTimeWithTolerance) {
      slot.end_time = startTime;
      slots.push(slot);
      slot = {
        id: i,
        start_time: startTime,
        end_time: endTime,
        pause: response.data[i].pause,
      };
      slots.push(slot);
      return;
    }
    slot = {
      id: i,
      start_time: startTime,
      end_time: endTime,
      pause: response.data[i].pause,
    };
    slots.push(slot);
  });

  if (slots.length === 0) {
    let slot = {
      start_time: dayStart,
      end_time: dayEnd,
    };
    slots.push(slot);
  } else if (slots[slots.length - 1].end_time < dayEnd) {
    let slot = {
      start_time: slots[slots.length - 1].end_time,
      end_time: dayEnd,
    };
    slots.push(slot);
  }
  return slots;
}
