import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import TimePicker from "../../component/form/time_picker";
import { useLanguage } from "../../context/language";

const defaultConfig = {
  day_start_time: new Date(new Date().setHours(8, 0, 0)),
  day_end_time: new Date(new Date().setHours(18, 0, 0)),
  pause_start_time: new Date(new Date().setHours(12, 0, 0)),
  pause_end_time: new Date(new Date().setHours(13, 0, 0)),
  week_work_days: [1, 2, 3, 4, 5],
};

const labelsWidth = 3;
const inputsWidth = 9;

export default function ConfigForm({ config = defaultConfig, onChange }) {
  const [T] = useLanguage();
  const updateConfig = (fields) =>
    onChange({ config: { ...config, ...fields } });

  useEffect(() => updateConfig({}), []);

  return (
    <>
      <Row className="align-items-center">
        <Col sm={labelsWidth} className="d-flex justify-content-center">
          <div>
            <b>{T("Daily Schedule")}</b>
          </div>
        </Col>
        <Col sm={inputsWidth}>
          <Row>
            <TimePicker
              label={`${T("From Time")} `}
              selected={new Date(config.day_start_time)}
              onChange={(time) => updateConfig({ day_start_time: time })}
            />
            <TimePicker
              label={`${T("To Time")} `}
              selected={new Date(config.day_end_time)}
              onChange={(time) => updateConfig({ day_end_time: time })}
            />
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm={labelsWidth} className="d-flex justify-content-center">
          <div>
            <b>{T("Daily Pause Schedule")}</b>
          </div>
        </Col>
        <Col sm={inputsWidth}>
          <Row>
            <TimePicker
              label={`${T("From Time")} `}
              selected={new Date(config.pause_start_time)}
              onChange={(time) => updateConfig({ pause_start_time: time })}
            />
            <TimePicker
              label={`${T("To Time")} `}
              selected={new Date(config.pause_end_time)}
              onChange={(time) => updateConfig({ pause_end_time: time })}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={labelsWidth} className="d-flex justify-content-center">
          <div>
            <b>{T("Work Days")}</b>
          </div>
        </Col>
        <Col sm={inputsWidth}>
          <Row>
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <Col key={day + "key"}>
                <Form.Group controlId={day + "check"}>
                  <Form.Check custom label={T(day)} type="checkbox" />
                </Form.Group>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}
