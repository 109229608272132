import React from "react";
import { Form, Col } from "react-bootstrap";

export default function TextInput({
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  onBlur,
  disabled,
  rows,
}) {
  return (
    <Col>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          {...(rows ? { as: "textarea", rows: rows } : {})}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Form.Group>
    </Col>
  );
}
