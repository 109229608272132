import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import Dashboard from "./dashboard/dashboard";
import NavigationBar from "./component/navbar";
import Login from "./auth/login";
import { useAppContext } from "./context/store";
import Clients from "./clients/clients";
import Users from "./users/users";
import Configure from "./configure/configure";

function App() {
  const [get] = useAppContext();

  useEffect(() => {
    console.log("authenticated changed", get.authenticated);
  }, [get.authenticated]);

  useEffect(() => {
    console.log("user changed");
  }, [get.user]);

  return (
    <Router>
      {get.authenticated ? (
        <>
          <NavigationBar />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/clients" component={Clients} />
            <Route path="/configure" component={Configure} />
            <Route path="/users" component={Users} />
          </Switch>
        </>
      ) : (
        <Switch>
          <Route path="*" component={Login} />
        </Switch>
      )}
    </Router>
  );
}

export default App;
