import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useAppContext } from "../../context/store";
import DateNavigation from "../../component/date_navigate";
import { useLanguage } from "../../context/language";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const userBorder = (color) => ({ borderLeft: `0.3rem solid ${color}` });

export default function MonthView({ onClick }) {
  const [monthGrid, setMonthGrid] = useState([]);
  const [get, set] = useAppContext();
  const [monthAppointments, setMonthAppointments] = useState({});
  const [loading, setLoading] = useState(true);
  const [T] = useLanguage();

  const daysInMonth =
    32 - new Date(get.date.year, get.date.month, 32).getDate();

  useEffect(() => {
    setLoading(true);
    let query = {
      start_time: new Date(get.date.year, get.date.month, 1),
      end_time: new Date(get.date.year, get.date.month + 1, 1),
    };

    set.appointments(query).then((response) => setMonthAppointments(response));
  }, [get.date, get.selectedUserID, get.selectedClientID]);

  useEffect(() => {
    setLoading(false);
  }, [monthAppointments]);

  useEffect(() => {
    let rows = [];
    const startDay = new Date(get.date.year, get.date.month, 1).getDay();
    for (let day = startDay; day < daysInMonth + startDay; day++) {
      if (day !== 0 && day % 7 === 0) {
        rows.push(day - startDay);
      }
    }
    rows.push(rows[rows.length - 1] + 7);
    setMonthGrid(rows);
  }, [get.date]);

  const viewDay = (day) => {
    set.date({ day: day });
    onClick();
  };

  return (
    <>
      <div className="card-header">
        <DateNavigation
          onPrev={() => set.date({ month: get.date.month - 1 })}
          timePicker={true}
          onNext={() => set.date({ month: get.date.month + 1 })}
          onReset={set.resetDate}
          selectUser={true}
        />
      </div>
      <div className="card-body">
        <Row>
          {days.map((day, i) => (
            <Col key={i + day} className="text-center rotate-text">
              {T(day)}
            </Col>
          ))}
        </Row>
        {monthGrid.map((row) => {
          return (
            <Row key={row} className="month-margin">
              {days.map((day, i) => {
                let dayNumber = row + i - 6;
                let dayText =
                  dayNumber < 1 ? "" : dayNumber > daysInMonth ? "" : dayNumber;
                let dayAppointments = [];
                if (typeof monthAppointments[dayText] !== "undefined") {
                  dayAppointments = monthAppointments[dayText];
                }

                return (
                  <Col
                    key={day + row}
                    className="month-card-content"
                    onClick={() => viewDay(row + i - 6)}
                  >
                    <div style={{ textAlign: "center"}}>
                      <b style={{ whiteSpace: "nowrap" }}>{dayText}</b>
                    </div>
                    {dayAppointments.map((app) => {
                      let usr = get.users.filter((u) => u.id === app.user_id);
                      let userStyle = userBorder(
                        usr.length > 0 ? usr[0].color : "#FFFFFF"
                      );

                      return (
                        <div
                          key={app.id}
                          className="month-appointment"
                          style={userStyle}
                        >
                          {usr.length > 0
                            ? `${usr[0].name} ${usr[0].surname}`
                            : ""}
                        </div>
                      );
                    })}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    </>
  );
}
