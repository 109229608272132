import React from "react";
import { Modal, Container, Form } from "react-bootstrap";
import TextInput from "../../component/form/text_input";
import { useLanguage } from "../../context/language";

const initForm = {
  name: "",
};

export default function CategoryForm({ form = initForm, setForm }) {
  const [T] = useLanguage();
  const updateForm = (fields) => setForm({ ...form, ...fields });

  return (
    <>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {T("Category")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form.Row>
            <TextInput
              label={T("Name")}
              placeholder={T("Name")}
              value={form.name}
              onChange={(e) => updateForm({ name: e.target.value })}
            />
          </Form.Row>
        </Container>
      </Modal.Body>
    </>
  );
}
