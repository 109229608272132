import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../context/store";
import ClientForm from "../../clients/modal/form";
import AppointmentForm from "./appointment_form";
import "./appointment.scss";
import { useLanguage } from "../../context/language";

const AppointmentFormHasErrors = (form, setErrors) => {
  if (form.start_time > form.end_time) {
    setErrors({
      end_time: "Time To Cannot be earlier than Time From",
    });
    return true;
  }
  return false;
};

const ClientFormHasErrors = (form, setErrors) => {
  if (typeof form.name !== "string" || form.name.length === 0) {
    setErrors({
      name: "Appointment should have a Client Name",
    });
    return true;
  }
  return false;
};

export default function AppointmentModal({ data, onHide }) {
  const [get, set] = useAppContext();
  const [T] = useLanguage();
  const [appointmentForm, setAppointmentForm] = useState(data);
  const [clientForm, setClientForm] = useState();
  const [clientSearch, setClientSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});
  const newAppoinment = !data.id;

  const insertClient = async () => {
    if (clientForm && typeof clientForm.id !== "number") {
      return set.newClient(clientForm).then((id) => {
        if (id) {
          return id;
        }
        console.log("NEW CLIENT ERROR");
      });
    }
    return clientForm ? clientForm.id : 0;
  };

  const submit = () => {
    if (AppointmentFormHasErrors(appointmentForm, setErrors)) {
      return;
    }
    if (ClientFormHasErrors(clientForm, setErrors)) {
      return;
    }
    setLoading(true);
    appointmentForm.payment = parseInt(appointmentForm.payment) || 0;
    if (newAppoinment) {
      insertClient().then((clientID) => {
        appointmentForm.client_id = clientID;
        set.newAppointment(appointmentForm).then((ok) => checkOK(ok));
      });
      return;
    }

    if (!edit) {
      appointmentForm.state = 2; //2 FINISHED APPOINTMENT STATE
    }

    insertClient().then((clientID) => {
      appointmentForm.client_id = clientID;
      set.editAppointment(appointmentForm).then((ok) => checkOK(ok));
    });
  };

  const checkOK = (ok) => {
    setLoading(false);
    if (ok) {
      onHide();
      return;
    }
    console.log("ERROR");
  };

  const cancel = () => {
    set.deleteAppointment(appointmentForm).then((ok) => {
      if (ok) {
        onHide();
        return;
      }
      console.log("ERROR");
    });
  };

  useEffect(() => {
    if (data.client_id && get.clients.length > 0) {
      let client = get.clients.filter(
        (client) => client.id === data.client_id
      )[0];
      setClientForm(client);
    }
  }, []);

  useEffect(() => {
    if (
      clientForm &&
      get.clients.length > 0 &&
      typeof clientForm.id !== "number" &&
      0 <
        clientForm.name.length +
          clientForm.surname.length +
          clientForm.email.length +
          clientForm.contact.length
    ) {
      let clientsList = get.clients.filter(
        (client) =>
          client.name.toLowerCase().includes(clientForm.name.toLowerCase()) &&
          client.surname
            .toLowerCase()
            .includes(clientForm.surname.toLowerCase()) &&
          client.email.toLowerCase().includes(clientForm.email.toLowerCase()) &&
          client.contact
            .toLowerCase()
            .includes(clientForm.contact.toLowerCase())
      );
      setClientSearch(clientsList);
    }
  }, [clientForm]);

  return (
    <Modal
      animation={false}
      show={true}
      dialogClassName="modal-60"
      aria-labelledby="appointment-modal"
      backdrop="static"
    >
      <AppointmentForm
        form={appointmentForm}
        setForm={setAppointmentForm}
        date={get.date}
        disabled={!edit && !newAppoinment}
        errors={errors}
      />
      <div style={{ position: "relative" }}>
        <ClientsList
          list={clientSearch}
          onItemClick={(client) => {
            setClientForm(client);
            setClientSearch([]);
          }}
          T={T}
        />
      </div>
      <ClientForm
        form={clientForm}
        setForm={setClientForm}
        onBlur={() => setClientSearch([])}
        createOnEdit={true}
        disabled={!edit && !newAppoinment}
        errors={errors}
      />
      <Modal.Footer>
        {confirmModal ? (
          <CancelConfirm
            onConfirm={cancel}
            onHide={() => setConfirmModal(false)}
            T={T}
          />
        ) : null}

        {data.state === 2 ? null : (
          <>
            <Col className="mr-auto">
              {!newAppoinment ? (
                <Button
                  variant="danger"
                  onClick={() => setConfirmModal(true)}
                  style={{ marginRight: "8px" }}
                >
                  {T("Cancel Appointment")}
                </Button>
              ) : null}
              {edit || newAppoinment ? (
                <Button variant="secondary" onClick={() => setClientForm()}>
                  {T("Clear Client Form")}
                </Button>
              ) : null}
            </Col>

            {!newAppoinment ? (
              <Button variant="secondary" onClick={() => setEdit(!edit)}>
                {edit ? T("Cancel Edit") : T("Edit Appointment")}
              </Button>
            ) : null}
            <Button variant="success" type="button" onClick={() => submit()}>
              {newAppoinment
                ? T("Submit")
                : edit
                ? T("Save Appointment")
                : T("Appointment Finished")}
            </Button>
          </>
        )}
        <Button onClick={onHide} style={{ marginRight: "19px" }}>
          {T("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ClientsList = ({ list, show = true, onItemClick, T }) => {
  if (list.length <= 0 || !show) {
    return null;
  }

  return (
    <div className="list-position-absolute">
      <Row className="list-item-header">
        <Col>{T("Name")}</Col>
        <Col>{T("Surname")}</Col>
        <Col>{T("Email")}</Col>
        <Col>{T("Contact")}</Col>
      </Row>
      {list.map((item, i) => (
        <Row
          key={item.name + i}
          className="list-item"
          onMouseDown={() => onItemClick(item)}
        >
          <Col className="list-col">
            <b>{item.name}</b>
          </Col>
          <Col className="list-col">
            <b>{item.surname}</b>
          </Col>
          <Col className="list-col">
            <b>{item.email}</b>
          </Col>
          <Col className="list-col">
            <b>{item.contact}</b>
          </Col>
        </Row>
      ))}
    </div>
  );
};

const CancelConfirm = ({ onConfirm, onHide, T }) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{T("Cancel Appointment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {T("Appointment will be deleted, this cannot be undone.")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onConfirm}>
          {T("Confirm")}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          {T("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
