import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAppContext } from "../../context/store";
import UserForm from "./form";
import { useLanguage } from "../../context/language";

export default function UserModal({ data, onHide }) {
  const [, set] = useAppContext();
  const [T] = useLanguage();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(data);
  const edit = !!data.id;

  const submit = () => {
    setLoading(true);
    if (edit) {
      set.editUser(form).then((ok) => {
        if (ok) {
          onHide();
          return;
        }
        alert("something went wrong");
        setLoading(false);
      });
      return;
    }
    set.newUser(form).then((id) => {
      if (id) {
        onHide();
        return;
      }
      alert("something went wrong");
      setLoading(false);
    });
  };

  return (
    <Modal
      animation={false}
      show={true}
      dialogClassName="modal-80"
      aria-labelledby="client-modal"
      backdrop="static"
    >
      <UserForm form={form} setForm={setForm} />
      <Modal.Footer>
        <Button onClick={submit} disabled={loading}>
          {edit ? T("Save") : T("Submit")}
        </Button>
        <Button onClick={onHide} disabled={loading}>
          {T("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
