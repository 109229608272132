import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAppContext } from "../../context/store";
import { useLanguage } from "../../context/language";
import ClientForm from "./form";

const ClientFormHasErrors = (form, setErrors) => {
  console.log(form);
  if (typeof form.name !== "string" || form.name.length === 0) {
    setErrors({
      name: "Appointment should have a Client Name",
    });
    return true;
  }
  return false;
};

export default function ClientModal({ data, onHide }) {
  const [get, set] = useAppContext();
  const [T] = useLanguage();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(data);
  const [errors, setErrors] = useState({});
  const edit = !!data.id;

  const submit = () => {
    if (ClientFormHasErrors(form, setErrors)) {
      return;
    }

    setLoading(true);
    if (edit) {
      set.editClient(form).then((ok) => {
        if (ok) {
          onHide();
          return;
        }
        alert("something went wrong");
        setLoading(false);
      });
      return;
    }
    set.newClient(form).then((id) => {
      if (id) {
        onHide();
        return;
      }
      alert("something went wrong");
      setLoading(false);
    });
  };

  return (
    <Modal
      animation={false}
      show={true}
      dialogClassName="modal-60"
      aria-labelledby="client-modal"
      backdrop="static"
    >
      <ClientForm form={form} setForm={setForm} errors={errors} />
      <Modal.Footer>
        <Button onClick={submit} disabled={loading}>
          {edit ? T("Save") : T("Submit")}
        </Button>
        <Button onClick={onHide} disabled={loading}>
          {T("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
