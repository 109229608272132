import React, { useEffect } from "react";
import { Modal, Container, Form, Col } from "react-bootstrap";
import TextInput from "../../component/form/text_input";
import { useLanguage } from "../../context/language";

const initForm = {
  name: "",
  surname: "",
  email: "",
  contact: "",
};

export default function ClientForm({
  form = initForm,
  setForm,
  onBlur,
  createOnEdit,
  disabled,
  errors = {},
}) {
  const [T] = useLanguage();
  const updateForm = (fields) => {
    console.log(form);
    setForm({
      ...form,
      ...fields,
      ...(createOnEdit ? { id: null } : {}),
    });
  };

  useEffect(() => updateForm({}), []);

  return (
    <>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {T("Client")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form.Row>
            {hasError(errors.name)}
            <TextInput
              label={T("Name")}
              placeholder={T("Name")}
              value={form.name}
              onChange={(e) => updateForm({ name: e.target.value })}
              onBlur={onBlur}
              disabled={disabled}
            />
            <TextInput
              label={T("Surname")}
              placeholder={T("Surname")}
              value={form.surname}
              onChange={(e) => updateForm({ surname: e.target.value })}
              onBlur={onBlur}
              disabled={disabled}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label={T("Email")}
              placeholder={T("Email")}
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value })}
              onBlur={onBlur}
              disabled={disabled}
            />
            <TextInput
              label={T("Contact")}
              placeholder={T("Contact")}
              value={form.contact}
              onChange={(e) => updateForm({ contact: e.target.value })}
              onBlur={onBlur}
              disabled={disabled}
            />
          </Form.Row>
        </Container>
      </Modal.Body>
    </>
  );
}

const hasError = (error) => {
  if (error) {
    return (
      <Col sm={12}>
        <Form.Text className="text-danger">{error}</Form.Text>
      </Col>
    );
  }
  return null;
};
