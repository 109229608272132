import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/store";
import DateNavigation from "../../component/date_navigate";
import DayCard from "../../component/daycard";
import AppointmentModal from "../modal/appointment";

export default function DayView({ query }) {
  const [get, set] = useAppContext();

  const [fillSlot, setFillSlot] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (!query) {
      query = {
        start_time: new Date(get.date.year, get.date.month, get.date.day),
        end_time: new Date(get.date.year, get.date.month, get.date.day + 1),
      };
    }

    set.dayAppointments(query);
  }, [query, get.date, get.selectedUserID, get.selectedClientID]);

  useEffect(() => {
    setLoading(false);
  }, [get.appointments]);

  return (
    <>
      <div className="card-header">
        <DateNavigation
          loading={loading}
          onPrev={() => set.date({ day: get.date.day - 1 })}
          timePicker={true}
          onNext={() => set.date({ day: get.date.day + 1 })}
          onReset={set.resetDate}
          selectUser={true}
        />
      </div>
      <div className="card-body">
        {get.appointments.slots.map((slot, i) =>
          typeof slot.id === "number" ? (
            <DayCard
              key={i}
              data={get.appointments.data[slot.id]}
              onClick={() => setFillSlot(get.appointments.data[slot.id])}
            />
          ) : (
            <DayCard key={i} data={slot} onClick={() => setFillSlot(slot)} />
          )
        )}

        {fillSlot ? (
          <AppointmentModal data={fillSlot} onHide={() => setFillSlot(null)} />
        ) : null}
      </div>
    </>
  );
}
