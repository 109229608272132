import React from "react";
import { Row, Col } from "react-bootstrap";
import { format } from "date-fns";
import { Circle, CheckCircleFill } from "react-bootstrap-icons";
import { useAppContext } from "../context/store";
import { useLanguage } from "../context/language";

const style = {
  row: {
    minHeight: "calc(100vh / 10)",
    margin: "0rem",
    padding: "0.2rem",
    borderBottom: "1px solid rgb(150,150,150, 0.2)",
    borderRadius: "0.4rem",
  },
  rowCompact: {
    minHeight: "calc(100vh / 15)",
    margin: "0rem",
    padding: "0.2rem",
    borderBottom: "1px solid rgb(150,150,150, 0.2)",
    borderRadius: "0.4rem",
  },
  currentRow: {
    animation: "blink 1s infinite ",
  },
  hour: {
    color: "rgb(150,150,150)",
    textAlign: "center",
  },
  description: {
    fontSize: "0.9rem",
    color: "rgb(150,150,150)",
  },
  finishedColor: "rgba(0,150,0,0.4)",
  blankColor: "rgba(0,0,0,0.1)",
  disabled: {
    backgroundColor: "#F7F7F7",
    cursor: "unset",
    color: "#95a5a6",
  },
};

const colors = {
  finished: {
    borderLeft: `0.3rem solid ${style.finishedColor}`,
  },
  scheduled: {
    borderLeft: `0.3rem solid rgba(0,0,150,0.4)`,
  },
  canceled: {
    borderLeft: "0.3rem solid rgba(255,0,0,0.4)",
  },
  blank: {},
};

const userBorder = (color) => ({ borderLeft: `0.3rem solid ${color}` });

export default function DayCard({ data, onClick, compact, mini, date }) {
  const [get] = useAppContext();
  const [T] = useLanguage();
  const usr = get.users.filter((u) => u.id === data.user_id);

  let stateStyle = colors.blank;
  if (data.id) {
    stateStyle = userBorder(usr.length > 0 ? usr[0].color : "#FFFFFF");
  }

  return (
    <Row
      className="align-items-center day-card"
      style={{
        ...(compact ? style.rowCompact : style.row),
        ...(new Date(data.start_time) < new Date() &&
        new Date(data.end_time) > new Date()
          ? style.currentRow
          : {}),
      }}
    >
      <Col xs={3} sm={1} md={1} style={style.hour}>
        <b>{format(new Date(data.start_time), "HH:mm")}</b>
        <br></br>
        <b>{format(new Date(data.end_time), "HH:mm")}</b>
      </Col>
      {mini ? null : date ? (
        <Col xs={9} sm={2} md={2} style={{ ...style.hour, textAlign: "left" }}>
          <b>
            {format(new Date(data.start_time), "iii dd MMM yyyy")
              .split(" ")
              .map((word) => `${T(word)} `)}
          </b>
        </Col>
      ) : (
        <Col
          sm={1}
          md={1}
          style={{ ...style.hour, textAlign: "left" }}
          className="d-none d-sm-block"
        >
          {data && data.state === 2 ? (
            <CheckCircleFill color={style.finishedColor} size={18} />
          ) : (
            <Circle color={style.blankColor} size={18} />
          )}
        </Col>
      )}
      <Col
        className="d-flex align-self-stretch align-content-around flex-wrap day-card-content"
        style={{ ...stateStyle, ...(data.pause ? style.disabled : {}) }}
        onClick={() => (data.pause ? {} : onClick())}
      >
        {data.id && get.user.type_id === 1 ? (
          <Col className="col-sm" xs={12}>
            <b>{usr.length > 0 ? `${usr[0].name} ${usr[0].surname}` : ""}</b>
          </Col>
        ) : null}
        {!mini ? (
          <Col className="col-sm" xs={12}>
            {data.id ? (
              <>
                {`${T("Client")}: `} <b>{data.client_name}</b>
              </>
            ) : data.pause ? (
              T("Pause")
            ) : (
              T("Click to add new appointment")
            )}
          </Col>
        ) : null}
        {data.id && !mini ? (
          <>
            <Col className="col-sm" xs={12}>
              {`${T("Category")}: `}
              <b>
                {get.categories.length > 0
                  ? get.categories.filter(
                      (cat) => cat.id === data.category_id
                    )[0].name
                  : ""}
              </b>
            </Col>
            <Col className="col-sm" xs={12}>
              {`${T("Payment")}: `}
              <b>{data.payment} &euro;</b>
            </Col>
          </>
        ) : null}

        {!compact && !mini ? (
          <Col sm={12} style={style.description}>
            {data.description}
          </Col>
        ) : null}
      </Col>
    </Row>
  );
}
