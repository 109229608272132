import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  ArrowLeft,
  ArrowRight,
  ArrowCounterclockwise,
  Plus,
} from "react-bootstrap-icons";
import { useAppContext } from "../context/store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLanguage } from "../context/language";

const style = {
  add: { lineHeight: "1.4rem", verticalAlign: "bottom" },
};

export default function DateNavigation({
  loading,
  onPrev,
  text,
  timePicker,
  onNext,
  onReset,
  onAdd,
  selectUser,
}) {
  const [get, set] = useAppContext();
  const [startDate, setStartDate] = useState(
    new Date(get.date.year, get.date.month, get.date.day)
  );
  const [T] = useLanguage();

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      ref={ref}
      className="btn-block no-border"
      variant="outline-primary"
      onClick={onClick}
    >
      <b>{value.split(" ").map((word) => `${T(word)} `)}</b>
    </Button>
  ));

  const changeDate = (date) => {
    set.date({
      day: new Date(date).getDate(),
      month: new Date(date).getMonth(),
      year: new Date(date).getFullYear(),
    });
    setStartDate(date);
  };

  useEffect(
    () => setStartDate(new Date(get.date.year, get.date.month, get.date.day)),
    [get.date]
  );

  return (
    <Row className="align-items-center">
      {onReset ? (
        <Col className="order-2">
          <Button
            className="btn-block no-border"
            variant="outline-primary"
            onClick={onReset}
            disabled={loading}
          >
            <b>{timePicker ? T("Today") : T("Reset")}</b>
            {/* <ArrowCounterclockwise size="1.3rem" /> */}
          </Button>
        </Col>
      ) : onAdd ? (
        <Col className="order-2"></Col>
      ) : null}
      {onPrev ? (
        <Col className="order-3">
          <Button
            className="btn-block no-border"
            variant="outline-primary"
            onClick={onPrev}
            disabled={loading}
          >
            <ArrowLeft size="1.3rem" />
          </Button>
        </Col>
      ) : null}
      {text ? (
        <Col className="text-center order-1 order-sm-4 col-sm" xs={12}>
          <div className="dashboard-header-text">{text}</div>
        </Col>
      ) : null}
      {timePicker ? (
        <Col className="order-1 order-sm-4 col-sm" xs={12}>
          <DatePicker
            dateFormat="iii dd MMM yyyy"
            selected={startDate}
            onChange={(date) => changeDate(date)}
            customInput={<CustomInput />}
            style={{ width: "100%" }}
          />
        </Col>
      ) : null}
      {onNext ? (
        <Col className="order-5">
          <Button
            className="btn-block no-border"
            variant="outline-primary"
            onClick={onNext}
            disabled={loading}
          >
            <ArrowRight size="1.3rem" />
          </Button>
        </Col>
      ) : null}
      {onAdd ? (
        <Col className="order-6">
          <Button
            className="btn-block no-border"
            variant="outline-primary"
            onClick={onAdd}
            disabled={loading}
          >
            <b>{T("Add")}</b>
            {/* <Plus size="1.3rem" /> */}
          </Button>
        </Col>
      ) : onReset && !selectUser ? (
        <Col className="order-6"></Col>
      ) : null}
      {selectUser ? (
        <Col className="order-6">
          <Form.Control
            as="select"
            value={get.selectedUserID}
            onChange={(e) => set.selectedUserID(parseInt(e.target.value))}
          >
            <option value={-1}>{T("All Users")}</option>
            {get.users.map((user, i) => (
              <option key={user.id + i} value={user.id}>
                {user.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      ) : null}
    </Row>
  );
}
