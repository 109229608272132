import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useAppContext } from "../context/store";
import { useLanguage } from "../context/language";
import DateNavigation from "../component/date_navigate";
import ClientModal from "./modal/client";
import { CardText } from "react-bootstrap-icons";

export default function Clients() {
  const [, set] = useAppContext();
  const [T] = useLanguage();
  const [clients, setClients] = useState([]);
  const [addClient, setAddClient] = useState(false);
  const [tableConfig, setTableConfig] = useState({
    page: 1,
    max_rows: 10,
  });

  useEffect(() => {
    if (!addClient) {
      set.clients(tableConfig).then((response) => {
        if (response) {
          setClients(response);
        } else {
          alert("Something went wrong!");
        }
      });
    }
  }, [tableConfig, addClient]);

  const changePage = (action) => {
    if (action === "previous" && tableConfig.page <= 1) {
      return;
    }
    setTableConfig({
      page:
        action === "reset"
          ? 1
          : tableConfig.page + (action === "next" ? 1 : -1),
      max_rows: tableConfig.max_rows,
    });
  };

  return (
    <Container className="dashboard-header">
      <div className="card border-light mb-3 card-view">
        <div className="card-header">
          <DateNavigation
            onAdd={() => setAddClient({})}
            onPrev={() => changePage("previous")}
            text={`${T("Page")} ${tableConfig.page}`}
            onNext={() => changePage("next")}
            onReset={() => changePage("reset")}
          />
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">{T("Name")}</th>
                <th scope="col">{T("Surname")}</th>
                <th scope="col" className="d-none d-sm-table-cell">
                  {T("Email")}
                </th>
                <th scope="col" className="d-none d-sm-table-cell">
                  {T("Contact")}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody className="card-header">
              {clients.map((client, i) => (
                <tr key={client.id + i}>
                  <th scope="row">{client.name}</th>
                  <td>{client.surname}</td>
                  <td className="d-none d-sm-table-cell">{client.email}</td>
                  <td className="d-none d-sm-table-cell">{client.contact}</td>
                  <td
                    onClick={() => setAddClient(client)}
                    className="td-action"
                  >
                    <CardText />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {addClient ? (
        <ClientModal data={addClient} onHide={() => setAddClient(null)} />
      ) : null}
    </Container>
  );
}
