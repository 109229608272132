import axios from "axios";

// const authority = "http://localhost:8000/";

var sessionKey = "APP-Session";

var config = {
  baseURL: "",
  headers: {
    "Auth-Session": (() => {
      let session = localStorage.getItem(sessionKey);
      if (session === null) {
        return "test";
      }
      return session;
    })(),
  },
  timeout: 1000,
};

var instance = axios.create(config);

export default class API {
  login = (data) =>
    POST("/user/login", data).then((response) => {
      if (response.status === 200) {
        localStorage.setItem(sessionKey, response.data.session);
        config = {
          baseURL: "",
          headers: {
            "Auth-Session": response.data.session,
          },
          timeout: 1000,
        };
        instance = axios.create(config);
      }
      return response;
    });

  logout = () =>
    POST("user/logout", {}).then((response) => {
      if (response.status === 200) {
        localStorage.removeItem(sessionKey);
        return true;
      }
      return false;
    });

  newAppointment = (data) => POST("/appointment/create", data);
  readAppointments = (query) => POST("/appointment/read", query);
  readAppointmentsByClient = (query) =>
    POST("/appointment/readbyclient", query);
  editAppointment = (data) => POST("appointment/update", data);
  deleteAppointment = (data) => POST("appointment/delete", data);

  newClient = (data) => POST("/client/create", data);
  readClients = (query) => POST("/client/read", query);
  editClient = (data) => POST("client/update", data);

  newUser = (data) => POST("/user/create", data);
  readUsers = (query) => POST("/user/read", query);
  editUser = (data) => POST("user/update", data);

  newCategory = (data) => POST("/category/create", data);
  readCategories = (query) => POST("/category/read", query);
  editCategory = (data) => POST("category/update", data);
}

async function POST(url, body) {
  if (config.headers["Auth-Session"] === "test" && url !== "/user/login") {
    return {
      data: null,
      status: -1,
    };
  }

  return instance.post(url, JSON.stringify(body)).then((response) => {
    return {
      data: response.data,
      status: response.status,
    };
  });
}
