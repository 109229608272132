import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DayView from "./day/view";
import MonthView from "./month/view";
import WeekView from "./week/view";
import { useLanguage } from "../context/language";
import ListView from "./list/view";

const DayCode = "Day";
const WeekCode = "Week";
const MonthCode = "Month";

const View = ({ viewCode, onMonthClick }) => {
  if (viewCode === DayCode) {
    return <DayView />;
  }
  if (viewCode === WeekCode) {
    return <WeekView />;
  }
  if (viewCode === MonthCode) {
    return <MonthView onClick={() => onMonthClick()} />;
  }

  return <ListView />;
};

export default function Dashboard() {
  const [view, setView] = useState(DayCode);
  const [T] = useLanguage();

  useEffect(() => {
    console.log("Rendering Dashboard");
    return () => console.log("Unmounting Dashboard");
  }, []);

  return (
    <Container className="dashboard-header">
      <ul className="nav nav-tabs">
        {["Day", "Week", "Month", "List"].map((d) => (
          <li className="nav-item" style={{ maxWidth: "25%" }} key={d}>
            <a
              className={view === d ? "nav-link active" : "nav-link"}
              data-toggle="tab"
              onClick={() => setView(d)}
            >
              {T(d)}
            </a>
          </li>
        ))}
      </ul>
      <div id="myTabContent" className="tab-content">
        <div className="card border-light mb-3 card-view border-topless transparent">
          <View viewCode={view} onMonthClick={() => setView(DayCode)} />
        </div>
      </div>
    </Container>
  );
}
