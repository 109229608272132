import React, { useState, useEffect, useContext, createContext } from "react";

var lang = {};

async function getLanguage() {
  return await import(`./languages/al.json`);
}

async function changeLanguage(code) {
  return await import(`./languages/${code}.json`);
}

export const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export default function LanguageProvider({ children }) {
  const [get, set] = InitLanguage();

  return (
    <LanguageContext.Provider value={[get, set]}>
      {children}
    </LanguageContext.Provider>
  );
}

export function InitLanguage() {
  const [language, setLanguage] = useState();

  useEffect(() => {
    console.log("Setting", language);
    getLanguage().then((lang) => setLanguage(lang));
  }, []);

  //
  return [
    (word) => (language ? language[word] || word : word),
    {
      change: (code) => changeLanguage(code).then((lang) => setLanguage(lang)),
    },
  ];
}
