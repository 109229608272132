import React, { useState, useEffect } from "react";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../context/store";
import { useLanguage } from "../context/language";

const loginKey = "APP-Login";

export default function Login() {
  const [, set] = useAppContext();
  const [T] = useLanguage();
  const [rememberMe, setRememberMe] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let email = localStorage.getItem(loginKey);
    if (typeof email === "string") {
      setRememberMe(true);
      setUser({
        email: email,
        password: user.password,
      });
    }
  }, []);

  return (
    <Container className="margin-top">
      <Row className="justify-content-center">
        <Col
          sm={4}
          className="align-self-center plate padding-all day-card-content-shadow"
        >
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{T("Email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={T("Email")}
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{T("Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={T("Password")}
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                custom
                label={T("Remember me")}
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="button"
              onClick={() => set.login(user, rememberMe)}
            >
              {T("Submit")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
