import React from "react";
import { Modal, Container, Form, Col } from "react-bootstrap";
import TextInput from "../../component/form/text_input";
import ConfigForm from "./config_form";
import { HuePicker } from "react-color";
import { useAppContext } from "../../context/store";
import { useLanguage } from "../../context/language";

const initForm = {
  name: "",
  surname: "",
  email: "",
  contact: "",
  type_id: 3,
  color: "#555555",
};

export default function UserForm({ form = initForm, setForm, onBlur }) {
  const [get] = useAppContext();
  const [T] = useLanguage();
  const updateForm = (fields) =>
    setForm({
      ...form,
      ...fields,
    });

  return (
    <>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {T("User")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form.Row>
            <TextInput
              label={T("Name")}
              placeholder={T("Name")}
              value={form.name}
              onChange={(e) => updateForm({ name: e.target.value })}
              onBlur={onBlur}
            />
            <TextInput
              label={T("Surname")}
              placeholder={T("Surname")}
              value={form.surname}
              onChange={(e) => updateForm({ surname: e.target.value })}
              onBlur={onBlur}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label={T("Email")}
              placeholder={T("Email")}
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value })}
              onBlur={onBlur}
            />
            <TextInput
              label={T("Contact")}
              placeholder={T("Contact")}
              value={form.contact}
              onChange={(e) => updateForm({ contact: e.target.value })}
              onBlur={onBlur}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label={T("Current Password")}
              placeholder={T("Current Password")}
              value={form.password}
              onChange={(e) => updateForm({ password: e.target.value })}
              onBlur={onBlur}
            />
            <TextInput
              label={T("New Password")}
              placeholder={T("New Password")}
              value={form.password}
              onChange={(e) => updateForm({ password: e.target.value })}
              onBlur={onBlur}
            />
          </Form.Row>
          <Form.Row>
            <Col
              sm={1}
              style={{
                margin: "5px",
                marginBottom: "16px",
                backgroundColor: form.color,
                borderRadius: "0.25rem",
              }}
            ></Col>
            <Col>
              <Form.Group>
                <Form.Label>{T("Color")}</Form.Label>
                <HuePicker
                  style={{ width: "100%" }}
                  color={form.color}
                  onChangeComplete={(color) => updateForm({ color: color.hex })}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group>
            <Form.Label>{T("Type")}</Form.Label>
            <Form.Control
              as="select"
              value={form.type_id}
              onChange={(e) =>
                updateForm({
                  type_id: parseInt(e.target.value),
                })
              }
            >
              {Object.keys(get.types).map((type_id, i) => (
                <option key={type_id + i} value={type_id}>
                  {get.types[type_id]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Container>
      </Modal.Body>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {T("Config")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <ConfigForm
            config={form.config}
            onChange={(config) => updateForm(config)}
          />
        </Container>
      </Modal.Body>
    </>
  );
}
