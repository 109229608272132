import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Col } from "react-bootstrap";
import { useAppContext } from "../../context/store";

export default function TimePicker({
  label,
  selected,
  onChange,
  disabled,
  interval,
  minTime,
  maxTime,
  includeTimesFromDay,
}) {
  const [get] = useAppContext();
  let includeTimes = null;
  if (includeTimesFromDay) {
    includeTimes = [];
    if (get.appointments.slots.length > 0) {
      get.appointments.slots.forEach((appointment) => {
        if (appointment.pause || typeof appointment.id === "number") {
          return;
        }
        includeTimes.push(appointment.start_time);
        let time = appointment.start_time;
        while (time < appointment.end_time) {
          time = new Date(time.setMinutes(time.getMinutes() + 1));
          includeTimes.push(time);
        }
        includeTimes.push(appointment.end_time);
      });
    }
  }

  return (
    <Col>
      <Form.Group>
        {label ? (
          <Form.Label style={{ paddingRight: "0.5rem" }}>{label}</Form.Label>
        ) : null}
        <DatePicker
          selected={selected}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          minTime={minTime || null}
          maxTime={maxTime || null}
          includeTimes={includeTimes}
          timeFormat="HH:mm"
          timeIntervals={interval || 30}
          timeCaption="time"
          dateFormat="HH:mm"
          className="form-control"
          disabled={disabled}
        />
      </Form.Group>
    </Col>
  );
}
