import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useAppContext } from "../context/store";
import DateNavigation from "../component/date_navigate";
import UserModal from "./modal/user";
import { CardText } from "react-bootstrap-icons";
import { useLanguage } from "../context/language";

export default function Users() {
  const [get, set] = useAppContext();
  const [T] = useLanguage();
  const [users, setUsers] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!addUser) {
      set.users().then((response) => {
        if (response) {
          setUsers(response);
        } else {
          alert("Something went wrong!");
        }
      });
    }
  }, [addUser]);

  return (
    <Container className="dashboard-header">
      <div className="card border-light mb-3 card-view">
        <div className="card-header">
          <DateNavigation
            onAdd={() => setAddUser({})}
            onPrev={() => setPage(page - 1)}
            text={`${"Page"} ${page}`}
            onNext={() => setPage(page + 1)}
            onReset={() => setPage(1)}
          />
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">{T("Name")}</th>
                <th scope="col">{T("Surname")}</th>
                <th scope="col" className="d-none d-sm-table-cell">
                  {T("Email")}
                </th>
                <th scope="col" className="d-none d-sm-table-cell">
                  {T("Contact")}
                </th>
                <th scope="col" className="d-none d-sm-table-cell">
                  {T("Type")}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.id + i}>
                  <th scope="row">{user.name}</th>
                  <td>{user.surname}</td>
                  <td className="d-none d-sm-table-cell">{user.email}</td>
                  <td className="d-none d-sm-table-cell">{user.contact}</td>
                  <td className="d-none d-sm-table-cell">
                    {get.types[user.type_id]}
                  </td>
                  <td onClick={() => setAddUser(user)} className="td-action">
                    <CardText />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {addUser ? (
        <UserModal data={addUser} onHide={() => setAddUser(null)} />
      ) : null}
    </Container>
  );
}
