import React, { useState, useEffect } from "react";
import { Form, Modal, Container, Col } from "react-bootstrap";
import { format } from "date-fns";
import TimePicker from "../../component/form/time_picker";
import TextInput from "../../component/form/text_input";
import { useAppContext } from "../../context/store";
import { useLanguage } from "../../context/language";

const initForm = (form, user) => ({
  id: form.id || 0,
  user_id: form.user_id || user.id,
  client_id: form.client_id || 1,
  category_id: form.category_id || 1,
  start_time:
    typeof form.start_time === "string"
      ? new Date(form.start_time)
      : form.start_time || new Date(),
  end_time:
    typeof form.end_time === "string"
      ? new Date(form.end_time)
      : form.end_time || new Date(),
  title: form.title || "",
  note: form.note || "",
  payment: form.payment || "",
  state: form.state || 1,
});

export default function AppointmentForm({
  form,
  setForm,
  includeTimesFromDay,
  disabled,
  errors,
}) {
  const [get] = useAppContext();
  const [T] = useLanguage();
  const data = initForm(form, get.user);
  const [durationTime, setDurationTime] = useState(
    timeDifference(data.start_time, data.end_time)
  );

  const updateForm = (fields) => {
    if (fields.end_time || fields.start_time) {
      setDurationTime(
        fields.end_time
          ? timeDifference(data.start_time, new Date(fields.end_time))
          : timeDifference(new Date(fields.start_time), data.end_time)
      );
    }
    setForm({ ...data, ...fields });
  };

  useEffect(
    () =>
      updateForm(
        get.selectedUserID === -1 ? {} : { user_id: get.selectedUserID }
      ),
    []
  );

  const setDuration = (time) => {
    setDurationTime(time);
    updateForm({
      end_time: new Date(
        data.start_time.getFullYear(),
        data.start_time.getMonth(),
        data.start_time.getDate(),
        data.start_time.getHours() + time.getHours(),
        data.start_time.getMinutes() + time.getMinutes()
      ),
    });
  };

  return (
    <Form>
      <Modal.Header>
        <Modal.Title id="appointment-modal">{T("appointment")}</Modal.Title>
        <Modal.Title id="appointment-modal">
          {format(data.start_time, "iii dd MMM yyyy")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form.Row>
            <Col style={{ marginLeft: "-15px" }}>
              <TimePicker
                label={T("From")}
                selected={data.start_time}
                onChange={(date) => updateForm({ start_time: new Date(date) })}
                interval={15}
                includeTimesFromDay={includeTimesFromDay}
                disabled={disabled}
              />
            </Col>
            <Col>
              <TimePicker
                label={T("Duration")}
                selected={durationTime}
                onChange={(date) => setDuration(date)}
                interval={5}
                disabled={disabled}
              />
            </Col>
            <Col>
              {hasError(errors.end_time)}
              <TimePicker
                label={T("To")}
                selected={data.end_time}
                onChange={(date) => updateForm({ end_time: new Date(date) })}
                interval={15}
                disabled={disabled}
              />
            </Col>
          </Form.Row>
          <Form.Group>
            <Form.Label>{T("User")}</Form.Label>
            <Form.Control
              as="select"
              disabled={disabled}
              value={data.user_id}
              onChange={(e) =>
                updateForm({
                  user_id: parseInt(e.target.value),
                })
              }
            >
              {get.users.map((user, i) => (
                <option key={user.id + i} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Row>
            <TextInput
              label={T("Title")}
              placeholder={T("Title")}
              value={data.title}
              onChange={(e) => updateForm({ title: e.target.value })}
              disabled={disabled}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              rows={3}
              label={T("Note")}
              placeholder={T("Note")}
              value={data.note}
              onChange={(e) => updateForm({ note: e.target.value })}
              disabled={false}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label={T("Payment")}
              placeholder={T("Payment")}
              type="number"
              value={data.payment}
              onChange={(e) => updateForm({ payment: e.target.value })}
              disabled={disabled}
            />
          </Form.Row>
          <Form.Group>
            <Form.Label>{T("Category")}</Form.Label>
            <Form.Control
              as="select"
              disabled={disabled}
              value={data.category_id}
              onChange={(e) =>
                updateForm({
                  category_id: parseInt(e.target.value),
                })
              }
            >
              {get.categories.map((category, i) => (
                <option key={category.id + i} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Container>
      </Modal.Body>
    </Form>
  );
}

function timeDifference(start, end) {
  return new Date(start).setHours(
    end.getHours() - start.getHours(),
    end.getMinutes() - start.getMinutes()
  );
}

const hasError = (error) => {
  if (error) {
    return <Form.Text className="text-danger">{error}</Form.Text>;
  }
  return null;
};
